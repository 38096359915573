const OVERLAY_ID = "tweety-overlay-id";

/**
 *
 * @returns {HTMLDivElement}
 */
export function createOverlay() {
  const overlay = document.createElement("div");
  overlay.id = OVERLAY_ID;
  overlay.classList.add("tweety-overlay");
  overlay.textContent = "Updating notifications..";

  const spinner = document.createElement("div");
  spinner.classList.add("hrm-spinner", "hrm-spinner-large");

  overlay.prepend(spinner);

  return overlay;
}

export function showOverlay() {
  if (!document.getElementById(OVERLAY_ID)) {
    document.body.appendChild(createOverlay());
  }
}

export function hideOverlay() {
  const currentOverlay = document.getElementById(OVERLAY_ID);

  if (currentOverlay) {
    document.body.removeChild(currentOverlay);
  }
}

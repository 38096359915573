import { createDetails } from "./components/details/details";
import { BREAKPOINT_SM_MIN } from "./designTokens";
import { SLOT_ID, WRAPPER_CLASS_NAME, WRAPPER_ID } from "./handleNotifications";
import { getNotifications, getUnread } from "./state";

/**
 *
 * @param {string} selector - CSS selector string
 */
export function waitForElementToExist(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, { subtree: true, childList: true });
  });
}

/**
 *
 * @returns boolean true if window's width is greater than breakpoint sm min value
 */
export function isMobile() {
  const windowWidth = window.innerWidth;

  return Boolean(windowWidth < BREAKPOINT_SM_MIN);
}

export function renderNotifications() {
  const slot = document.getElementById(SLOT_ID);

  if (!slot) {
    return;
  }

  //remove current notifications
  slot.innerHTML = "";

  // Create a wrapper to position notifications
  const wrapper = document.createElement("div");
  wrapper.id = WRAPPER_ID;
  wrapper.className = WRAPPER_CLASS_NAME;

  const notifications = getNotifications();
  const unread = getUnread();
  const details = createDetails(notifications, unread);

  wrapper.appendChild(details);
  slot.appendChild(wrapper);
}

define("@makemusicinc/sm-ember-models/adapters/recipients-sharing", ["exports", "@makemusicinc/sm-ember-models/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class RecipientsSharing
   * @constructor
   * @extends ApplicationAdapter
   */

  class RecipientsSharing extends _application.default {
    buildURLWithSnapshot(id, snapshot) {
      return `${this.host}/sharings/${snapshot.belongsTo('sharing').id}/recipients_sharings/${id}`;
    }
    urlForDeleteRecord(id, _, snapshot) {
      return this.buildURLWithSnapshot(id, snapshot);
    }
    urlForUpdateRecord(id, _, snapshot) {
      return this.buildURLWithSnapshot(id, snapshot);
    }
  }
  _exports.default = RecipientsSharing;
});
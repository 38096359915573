define("@makemusicinc/sm-ember-models/models/classroom", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * A group of students owned by a teacher
   *
   * @class Classroom
   * @constructor
   */
  let Classroom = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('number'), _dec4 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec5 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec6 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec7 = (0, _model.attr)('number'), _dec8 = (0, _model.attr)('boolean'), _dec9 = (0, _model.belongsTo)('user', {
    inverse: null
  }), _dec10 = (0, _model.belongsTo)('grading-scale'), _dec11 = (0, _model.belongsTo)('grading-calendar'), _dec12 = (0, _model.belongsTo)('school'), _dec13 = (0, _model.hasMany)('user', {
    inverse: null
  }), _dec14 = (0, _model.hasMany)('assigned-task'), _dec15 = (0, _model.hasMany)('assignment'), _class = class Classroom extends _model.default {
    constructor() {
      super(...arguments);
      /**
       * The name given by the teacher to this class
       *
       * @property name
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "name", _descriptor, this);
      /**
       * The display name of the teacher to this class
       *
       * @property teacherDisplayName
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "teacherDisplayName", _descriptor2, this);
      /**
       * The school year corresponding to this class
       *
       * @property year
       * @category Attribute
       * @type {Number}
       */
      _initializerDefineProperty(this, "year", _descriptor3, this);
      /**
       * Is this class premium
       *
       * @property premium
       * @category Attribute
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "premium", _descriptor4, this);
      /**
       * Is this class assignable (premium content for premium classes only)
       *
       * @property assignable
       * @category Attribute
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "assignable", _descriptor5, this);
      /**
       * Is this class archived
       *
       * @property archived
       * @category Attribute
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "archived", _descriptor6, this);
      /**
       * The uid from admin
       *
       * @property classUid
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "classUid", _descriptor7, this);
      /**
       * Indicates if Personal Identifiable Information (PII) of students
       * has to be obfuscated to teachers.
       * This settings can be changed by school administrators.
       *
       * @property hideStudents
       * @category Attribute
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "hideStudents", _descriptor8, this);
      /**
       * The class teacher
       *
       * @property teacher
       * @category Relationship
       * @type {User}
       */
      _initializerDefineProperty(this, "teacher", _descriptor9, this);
      /**
       * The gradingScale this class belongs to
       *
       * @property gradingScale
       * @category Relationship
       * @type {GradingScale}
       */
      _initializerDefineProperty(this, "gradingScale", _descriptor10, this);
      /**
       * The gradingCalendar this class belongs to
       *
       * @property gradingCalendar
       * @category Relationship
       * @type {GradingCalendar}
       */
      _initializerDefineProperty(this, "gradingCalendar", _descriptor11, this);
      /**
       * The school this class belongs to
       *
       * @property school
       * @category Relationship
       * @type {School}
       */
      _initializerDefineProperty(this, "school", _descriptor12, this);
      /**
       * The class students
       *
       * @property students
       * @category Relationship
       * @type {User}
       */
      _initializerDefineProperty(this, "students", _descriptor13, this);
      /**
       * The assignedTasks given to this class
       *
       * @property assignedTasks
       * @category Relationship
       * @type {AssignedTask}
       */
      _initializerDefineProperty(this, "assignedTasks", _descriptor14, this);
      /**
       * The assignments given to this class
       *
       * @property assignments
       * @category Relationship
       * @type {Assignment}
       */
      _initializerDefineProperty(this, "assignments", _descriptor15, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "teacherDisplayName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "year", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "premium", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "assignable", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "archived", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "classUid", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hideStudents", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "teacher", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "gradingScale", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "gradingCalendar", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "school", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "students", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "assignedTasks", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "assignments", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});
define("@makemusicinc/sm-ember-models/models/abstract-task", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Abstract class from which AssignedTask and TaskTemplate inherit relationships
   * In order to allow polymorphism
   *
   * @class AbstractTask
   * @constructor
   */
  let AbstractTask = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('date'), _dec4 = (0, _model.belongsTo)('movement'), _dec5 = (0, _model.hasMany)('task-part', {
    inverse: 'task'
  }), _dec6 = (0, _model.hasMany)('rubrics-task', {
    inverse: 'task'
  }), _dec7 = (0, _model.belongsTo)('task-template', {
    inverse: null
  }), _class = class AbstractTask extends _model.default {
    constructor() {
      super(...arguments);
      /**
       * Name of the abstract task
       *
       * @property name
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "name", _descriptor, this);
      /**
       * Instructions from the teacher
       *
       * @property generalInstructions
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "generalInstructions", _descriptor2, this);
      /**
       * Date of creation of this task
       *
       * @property createdAt
       * @category Attribute
       * @type {Date}
       */
      _initializerDefineProperty(this, "createdAt", _descriptor3, this);
      /**
       * The movement this task is made for
       *
       * @property movement
       * @category Relationship
       * @type {Movement}
       */
      _initializerDefineProperty(this, "movement", _descriptor4, this);
      /**
       * The taskParts this task contains
       *
       * @property taskParts
       * @category Relationship
       * @type {Array<TaskPart>}
       */
      _initializerDefineProperty(this, "taskParts", _descriptor5, this);
      /**
       * The rubricsTasks this task contains
       *
       * @property rubricsTasks
       * @category Relationship
       * @type {Array<RubricTask>}
       */
      _initializerDefineProperty(this, "rubricsTasks", _descriptor6, this);
      /**
       * The TaskTemplate used to make this task
       *
       * @property parent
       * @category Relationship
       * @type {TaskTemplate}
       */
      _initializerDefineProperty(this, "parent", _descriptor7, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "generalInstructions", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "movement", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "taskParts", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "rubricsTasks", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "parent", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});
define("@makemusicinc/sm-ember-models/models/badge-setting", ["exports", "@ember-data/model", "@ember/array", "@ember/object"], function (_exports, _model, _array, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const ACTION_PAST_TIME_DICT = (0, _array.A)([{
    key: 'days',
    label: 'Day(s)'
  }, {
    key: 'weeks',
    label: 'Week(s)'
  }, {
    key: 'months',
    label: 'Month(s)'
  }]);
  /**
   * Represents required accomplishments for a student to earn an award
   *
   * @class BadgeSetting
   * @constructor
   */
  let BadgeSetting = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('number'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('number'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.belongsTo)('badge'), _dec7 = (0, _model.belongsTo)('classroom'), _class = class BadgeSetting extends _model.default {
    constructor() {
      super(...arguments);
      /**
       * Tracked action type
       * Possible values: score_90pc, score_85pc, score_80pc, play_or_record
       * Refer to `actionTypeDict`
       *
       * @property actionType
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "actionType", _descriptor, this);
      /**
       * Tracked action count
       *
       * @property actionCount
       * @category Attribute
       * @type {Number}
      -   */
      _initializerDefineProperty(this, "actionCount", _descriptor2, this);
      /**
       * Tracked action unit
       * Possible values: titles, times_each_day, minutes_each_day, times, minutes
       * Refer to `actionUnitDict`
       *
       * @property actionUnit
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "actionUnit", _descriptor3, this);
      /**
       * Tracked time count
       *
       * @property actionPastTimeCount
       * @category Attribute
       * @type {Number}
      -   */
      _initializerDefineProperty(this, "actionPastTimeCount", _descriptor4, this);
      /**
       * Tracked action unit
       * Possible values: days, weeks, months
       * Refer to `actionPastTimeUnitDict`
       *
       * @property actionPastTimeUnit
       * @category Attribute
       * @type {String}
      -   */
      _initializerDefineProperty(this, "actionPastTimeUnit", _descriptor5, this);
      /**
       * The related badge
       *
       * @property badge
       * @category Relationship
       * @type {Badge}
       */
      _initializerDefineProperty(this, "badge", _descriptor6, this);
      /**
       * The related SmartMusic class
       *
       * @property classroom
       * @category Relationship
       * @type {Classroom}
       */
      _initializerDefineProperty(this, "classroom", _descriptor7, this);
    }
    /**
     * @property actionTypeDict
     * @category Property
     * @type {Array<Object>}
     */
    get actionTypeDict() {
      const badge = (0, _object.get)(this, 'badge');
      if (!badge) {
        return (0, _array.A)([]);
      }
      switch ((0, _object.get)(badge, 'name')) {
        case 'Accuracy':
          return (0, _array.A)([{
            key: 'score_90pc',
            label: 'Score +90% on'
          }, {
            key: 'score_85pc',
            label: 'Score +85% on'
          }, {
            key: 'score_80pc',
            label: 'Score +80% on'
          }]);
        default:
          return (0, _array.A)([{
            key: 'play_or_record',
            label: 'Play or record'
          }]);
      }
    }

    /**
     * @property actionUnitDict
     * @category Property
     * @type {Array<Object>}
     */
    get actionUnitDict() {
      const badge = (0, _object.get)(this, 'badge');
      switch ((0, _object.get)(badge, 'name')) {
        case 'Accuracy':
          return (0, _array.A)([{
            key: 'titles',
            label: 'Different title(s)'
          }]);
        case 'Consistency':
          return (0, _array.A)([{
            key: 'times_each_day',
            label: 'Time(s) each day'
          }, {
            key: 'minutes_each_day',
            label: 'Minute(s) each day'
          }]);
        default:
          return (0, _array.A)([{
            key: 'times',
            label: 'Time(s)'
          }, {
            key: 'minutes',
            label: 'Minute(s)'
          }]);
      }
    }

    /**
     * Returns the badge description
     * according to its settings values
     *
     * @property description
     * @category Property
     * @type {String}
     */
    get description() {
      const badge = (0, _object.get)(this, 'badge');
      const badgeContentType = (0, _object.get)(badge, 'contentType');
      const actionUnit = this.actionUnitDict.findBy('key', this.actionUnit);
      const actionCount = this.actionCount;
      const actionPastTimeCount = this.actionPastTimeCount;
      const actionType = this.actionTypeDict.findBy('key', this.actionType);
      const actionPastTimeUnit = this.actionPastTimeUnitDict.findBy('key', this.actionPastTimeUnit);
      if (actionType && actionUnit && actionPastTimeUnit) {
        return `${actionType.label} ${actionCount} ${actionUnit.label.toLowerCase()} over the past ${actionPastTimeCount} ${actionPastTimeUnit.label.toLowerCase()} for ${badgeContentType} content`;
      }
      return '';
    }
    get actionPastTimeUnitDict() {
      return ACTION_PAST_TIME_DICT;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "actionType", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "actionCount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "actionUnit", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "actionPastTimeCount", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "actionPastTimeUnit", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "badge", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "classroom", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});
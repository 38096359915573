import NoNotifsImg from "@makemusicinc/hrm-styles/assets/stylesheets/tokens/vector/illustrations/spot/no-active-students.svg";
import { markAllAsRead } from "../../fetchers.js";
import { DETAILS_ID } from "../../handleNotifications.js";
import { isMobile, renderNotifications } from "../../helpers.js";
import { setNotifications, setUnread } from "../../state.js";
import { renderItem } from "../item/item.js";
import { hideOverlay, showOverlay } from "../overlay/overlay.js";
import { createFullScreenSummary, createSummary } from "../summary/summary.js";
import { ToastStatus, addToast } from "../toast/toast.js";
import "./../../types.js";

/**
 * Create an <ol/> list of notifications
 * @param {import("./../../types.js").UserNotification[]} notifications - list of notifications to be displayed
 * @returns {HTMLOListElement}
 */
export function createNotificationsList(notifications) {
  const list = document.createElement("ol");
  list.className = "tweety-item-list";

  const sortedNotifications = notifications.sort(
    (a, b) => parseInt(b.timestamp, 10) - parseInt(a.timestamp, 10)
  );

  sortedNotifications.forEach((notification) => renderItem(list, notification));

  return list;
}

/**
 * @returns {HTMLDivElement}
 */
export function createEmptyState() {
  const wrapper = document.createElement("div");
  wrapper.className = "hrm-empty-state";

  const img = document.createElement("img");
  img.src = NoNotifsImg;
  img.alt = "";
  img.className = "hrm-empty-state-image";

  const title = document.createElement("span");
  title.className = "hrm-empty-state-title";
  title.textContent = "Nothing to see here";

  const caption = document.createElement("p");
  caption.className = "hrm-empty-state-caption";
  caption.textContent = "You don't have any notifications yet";

  wrapper.appendChild(img);
  wrapper.appendChild(title);
  wrapper.appendChild(caption);

  return wrapper;
}

/**
 * Create a <div/> element with:
 * a "Unread" text
 * a hrm-counter displaying the number of unread notifications
 * a "Mark all as read" button
 * @param {number} unread
 * @returns {HTMLDivElement}
 */
export function createUnreadStatusBar(unread) {
  const unreadWrapper = document.createElement("div");
  unreadWrapper.className = "tweety-popover-unread-bar-label";

  const unreadCount = document.createElement("span");
  unreadCount.className = "hrm-counter";
  unreadCount.textContent = unread.toString();

  const unreadLabel = document.createElement("span");
  unreadLabel.textContent = "Unread";

  unreadWrapper.appendChild(unreadLabel);
  unreadWrapper.appendChild(unreadCount);

  const markAllAsReadBtn = document.createElement("button");
  markAllAsReadBtn.type = "button";
  markAllAsReadBtn.onclick = async () => {
    showOverlay();
    try {
      const { notifications, unread } = await markAllAsRead();

      setNotifications(notifications);
      setUnread(unread);

      renderNotifications();
      openDetails(DETAILS_ID);
    } catch (error) {
      addToast(
        ToastStatus.ERROR,
        "Error while marking all notifications as read"
      );
      hideOverlay();
      throw new Error(`Failed to mark all items as read: ${error}`);
    }
    hideOverlay();
  };
  markAllAsReadBtn.className = "hrm-btn hrm-btn-plain hrm-btn-s";
  const markAllAsReadText = document.createElement("span");
  markAllAsReadText.className = "hrm-btn-label";
  markAllAsReadText.textContent = "Mark all as read";
  markAllAsReadBtn.appendChild(markAllAsReadText);

  if (!unread) {
    markAllAsReadBtn.disabled = true;
  }

  const unreadStatusBar = document.createElement("div");
  unreadStatusBar.className = "tweety-popover-unread-bar";
  unreadStatusBar.appendChild(unreadWrapper);
  unreadStatusBar.appendChild(markAllAsReadBtn);

  return unreadStatusBar;
}

/**
 *
 * @param {import("./../../types.js").UserNotification[]} notifications
 * @param {number} unread
 * @param {HTMLDivElement} wrapper
 */
export function appendPopoverContent(notifications, unread, wrapper) {
  if (!notifications.length) {
    const emptyState = createEmptyState();
    wrapper.appendChild(emptyState);

    return;
  }

  const unreadStatusBar = createUnreadStatusBar(unread);
  const notificationsList = createNotificationsList(notifications);

  wrapper.appendChild(unreadStatusBar);
  wrapper.appendChild(notificationsList);
}

/**
 * Creates a <details/> element
 * content: a list of user notifications
 * @param {import("./../../types.js").UserNotification[]} notifications - list of notifications to be displayed
 * @param {number} unread - number of unread notifications
 */
export function createDetails(notifications, unread) {
  const DETAILS_CLASS_NAME = "tweety-popover";
  const DETAILS_ID = "tweety-popover";
  const SUMMARY_ID = "tweety-notifications-summary";
  const FULL_SCREEN_SUMMARY_ID = "tweety-notifications-summary-full-screen";
  const HEADER_ID = "tweety-popover-content-header";

  const details = document.createElement("details");
  details.className = DETAILS_CLASS_NAME;
  details.id = DETAILS_ID;

  const summary = createSummary(unread);
  summary.id = SUMMARY_ID;
  const fullScreenSummary = createFullScreenSummary();
  fullScreenSummary.id = FULL_SCREEN_SUMMARY_ID;

  const contentWrapper = document.createElement("div");
  contentWrapper.className = "tweety-popover-content";

  const header = document.createElement("div");
  header.textContent = "Notifications";
  header.className = HEADER_ID;
  header.id = HEADER_ID;
  header.setAttribute("data-testid", HEADER_ID);
  contentWrapper.appendChild(header);

  appendPopoverContent(notifications, unread, contentWrapper);

  details.prepend(summary);
  details.append(contentWrapper);

  function hideDetails(event) {
    if (!event.target?.closest(`#${DETAILS_ID}`)) {
      details.removeAttribute("open");
    }
  }

  details.addEventListener("toggle", () => {
    // hide details content on click outside
    details.open
      ? document.addEventListener("click", hideDetails)
      : document.removeEventListener("click", hideDetails);

    // use specific style on summary when details is open
    const openDetailsClassName = "tweety-summary-open";
    details.open
      ? summary.classList.add(openDetailsClassName)
      : summary.classList.remove(openDetailsClassName);

    if (details.open) {
      if (isMobile()) {
        // Display full-screen summary when details is open on mobile
        if (Boolean(details.querySelector(`#${SUMMARY_ID}`))) {
          details.removeChild(summary);
          details.prepend(fullScreenSummary);
          details.className = "tweety-popover-full-screen";
        }

        // Remove header title when details is open on mobile
        if (Boolean(contentWrapper.querySelector(`#${HEADER_ID}`))) {
          contentWrapper.removeChild(header);
        }
      }
    } else {
      if (Boolean(details.querySelector(`#${FULL_SCREEN_SUMMARY_ID}`))) {
        details.removeChild(fullScreenSummary);
        details.prepend(summary);
        details.className = "tweety-popover";
      }

      // Add header on desktop
      if (!Boolean(contentWrapper.querySelector(`#${HEADER_ID}`))) {
        contentWrapper.prepend(header);
      }
    }
  });

  return details;
}

/**
 * @param {string} id
 */
export function openDetails(id) {
  document.getElementById(id)?.setAttribute("open", "true");
}

define("@makemusicinc/sm-ember-models/adapters/sharing", ["exports", "@makemusicinc/sm-ember-models/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class SharingAdapter
   * @constructor
   * @extends ApplicationAdapter
   */

  class Sharing extends _application.default {
    /**
     * Extends the [Ember Data JSON API Adapter](https://api.emberjs.com/ember-data/3.15/classes/JSONAPIAdapter/methods/query?anchor=updateRecord)
     * updateRecord method with `addRecipientsSharings` adapter option.
     *  - it would reduce dramatically the amount of needed requests and only ids are necessary
     *  - in order to add a set of recipients sharings: `POST /sharings/:id/recipients_sharings`
     *  - the serialized will take those created recipients sharings and create a sharing update payload from it.
     *
     * @method updateRecord
     * @public
     * @param {Object} store
     * @param {Object} type
     * @param {Object} snapshot
     */

    updateRecord(store, type, snapshot) {
      const {
        adapterOptions = {}
      } = snapshot;
      if (adapterOptions.addRecipientsSharings) {
        return this.addRecipientsSharings(snapshot);
      }
      if (adapterOptions.removeAllRecipientsSharings) {
        return this.removeAllRecipientsSharings(snapshot);
      }
      return super.updateRecord(store, type, snapshot);
    }

    /**
     * Call for removing all recipients sharings from a sharing.
     * @method removeAllRecipientsSharings
     * @private
     * @param {string} sharingId
     */
    removeAllRecipientsSharings(snapshot) {
      const data = snapshot.serialize({});
      const url = this.urlForRemoveAllRecipientsSharings(snapshot);
      return this.ajax(url, 'PATCH', {
        data
      });
    }

    /**
     * Generates a correct endpoint URL based on the sharing id
     * in order to remove all recipients sharings
     * @method urlForRemoveAllRecipientsSharings
     * @private
     * @param {Object} snapshot
     */
    urlForRemoveAllRecipientsSharings(snapshot) {
      return `${this.host || ''}/sharings/${snapshot.id}/relationships/recipients_sharings`;
    }

    /**
     * Generates a correct endpoint URL based on the sharing id
     * in order to add recipients sharings
     * @method urlForUpdateRecipientsSharings
     * @private
     * @param {Object} snapshot
     */
    urlForUpdateRecipientsSharings(snapshot) {
      const host = this.host || '';
      const sharingId = snapshot.id;
      return `${host}/sharings/${sharingId}/recipients_sharings`;
    }

    /**
     * Request handler when adding new recipients sharings
     * * get correct endpoint URL
     * * use POST method instead of PATCH
     * @method addRecipientsSharings
     * @private
     * @param {Object} snapshot
     */

    addRecipientsSharings(snapshot) {
      const data = snapshot.serialize({});
      const url = this.urlForUpdateRecipientsSharings(snapshot);
      return this.ajax(url, 'POST', {
        data
      });
    }
  }
  _exports.default = Sharing;
});
define("@makemusicinc/sm-ember-models/models/badge", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * @class Badge
   * @constructor
   */
  let Badge = _exports.default = (_dec = (0, _model.attr)('string'), _class = class Badge extends _model.default {
    constructor() {
      super(...arguments);
      /**
       * Badge name
       * Possible values: Discipline, Discovery, Accuracy, Consistency
       * @property name
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "name", _descriptor, this);
    }
    /**
     * Badge content-type
     * Type of content used to compute the badge
     *
     * @property contentType
     * @category Property
     * @type {String}
     */
    get contentType() {
      switch (this.name) {
        case 'Discipline':
          return 'assigned';
        case 'Discovery':
          return 'non-assigned (exploration)';
        case 'Accuracy':
        case 'Consistency':
        default:
          return 'any';
      }
    }

    /**
     * Badge order
     * Allow sorting according to badge name
     *
     * @property name
     * @category Property
     * @type {Number}
     */
    get order() {
      switch (this.name) {
        case 'Discipline':
          return 1;
        case 'Discovery':
          return 2;
        case 'Consistency':
          return 3;
        case 'Accuracy':
          return 4;
        default:
          return -1;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});
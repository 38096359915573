import { openDetails } from "./components/details/details";
import { BREAKPOINT_SM_MIN } from "./designTokens";
import { fetchNotifications, setCurrentEnv } from "./fetchers";
import {
  isMobile,
  renderNotifications,
  waitForElementToExist,
} from "./helpers";
import { setNotifications, setUnread } from "./state";
import "./types";

let currentWindowWidth = window.innerWidth;

export const SLOT_ID = "tweety-notifications-slot";
export const SLOT_SELECTOR = `#${SLOT_ID}`;

export const WRAPPER_ID = "tweety-notifications-wrapper";
export const WRAPPER_CLASS_NAME = "tweety-wrapper";

export const DETAILS_ID = "tweety-popover";

async function displayNotifications() {
  const notificationsSlot = await waitForElementToExist(SLOT_SELECTOR);

  if (!notificationsSlot) return;

  // Get env value from consumer app
  setCurrentEnv(notificationsSlot.getAttribute("data-env") || "uat");

  try {
    const { notifications, unread } = await fetchNotifications();

    setNotifications(notifications);
    setUnread(unread);
    renderNotifications();
  } catch (error) {
    // Avoid to throw an error to Sentry if the user's cookie is expired
    if (error.statusCode !== 401) {
      throw new Error(`Fetch notification failed: ${error}`);
    }
  }
}

function listenToUrlChange() {
  let previousUrl = document.location.href;

  const observer = new MutationObserver(() => {
    const currentUrl = document.location.href;

    if (currentUrl !== previousUrl) {
      previousUrl = currentUrl;
      displayNotifications();
    }
  });

  observer.observe(document.body, { subtree: true, childList: true });
}

function listenToMobileDesktopSwitch() {
  addEventListener("resize", async () => {
    const isNewScreenMobile = isMobile();
    const isOpen = document.getElementById(DETAILS_ID)?.hasAttribute("open");

    if (isNewScreenMobile && currentWindowWidth > BREAKPOINT_SM_MIN) {
      // switched from desktop to mobile navigation
      await waitForElementToExist(".hrm-topbar");
      renderNotifications();
    }
    if (!isNewScreenMobile && currentWindowWidth <= BREAKPOINT_SM_MIN) {
      // switched from mobile to desktop navigation
      await waitForElementToExist(".hrm-appbar");
      renderNotifications();
    }

    if (isOpen) openDetails(DETAILS_ID);

    currentWindowWidth = window.innerWidth;
  });
}

export async function tweety() {
  await displayNotifications();
  listenToUrlChange();
  listenToMobileDesktopSwitch();
}

define("@makemusicinc/sm-ember-models/adapters/application", ["exports", "@ember/object", "@ember-data/adapter/json-api"], function (_exports, _object, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class ApplicationAdapter
   * @constructor
   * @extends JSONAPIAdapter
   */

  class Application extends _jsonApi.default {
    /**
     * Adds include credentials option
     * @method ajaxOptions
     * @public
     */
    ajaxOptions(url, type, options) {
      const _options = super.ajaxOptions(url, type, options);
      return {
        ..._options,
        credentials: 'include'
      };
    }

    /**
     * Adds scope `s` (which equals current app name) to payloads
     * @method ajaxOptions
     * @public
     */
    ajax(url, type) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      options.data = options.data || {};
      return super.ajax(url, type, options);
    }
    buildQuery(snapshot) {
      const query = super.buildQuery(snapshot);
      if (snapshot) {
        const {
          adapterOptions
        } = snapshot;
        if (adapterOptions) {
          const options = adapterOptions;
          const fieldsOptions = (0, _object.get)(options, 'fields');
          if (fieldsOptions) {
            const initialFields = (0, _object.get)(query, 'fields');
            const fields = {
              ...initialFields,
              ...fieldsOptions
            };
            return {
              ...query,
              fields
            };
          }
        }
      }
      return query;
    }
  }
  _exports.default = Application;
});
/**
 * Create a <summary/> element
 * styles: harmonica tertiary button icon
 * options: visual indicator if the user has unread notification
 * @param {number} unread
 * @returns {HTMLElement}
 */
export function createSummary(unread) {
  const hasUnreadNotifications = Boolean(unread);

  const summary = document.createElement("summary");
  summary.className =
    "tweety-summary hrm-btn hrm-btn-m hrm-btn-square hrm-btn-plain";

  if (hasUnreadNotifications) {
    summary.ariaLabel = `${unread} new notifications`;
    summary.setAttribute("class", `${summary.className} hrm-btn-flagged`);
  } else {
    summary.ariaLabel = "See notifications";
  }

  const icon = document.createElement("span");
  icon.ariaHidden = "true";
  icon.className = "hrm-icon hrm-btn-icon hrm-icon-notifications hrm-icon-s";

  summary.appendChild(icon);

  return summary;
}

/**
 * @returns {HTMLElement}
 */
export function createFullScreenSummary() {
  const summary = document.createElement("summary");
  summary.className = "tweety-summary-full-screen";
  summary.setAttribute("data-testid", "tweety-summary-full-screen");

  const icon = document.createElement("span");
  icon.className = "hrm-btn-icon hrm-icon hrm-icon-close";
  icon.ariaHidden = "true";
  summary.appendChild(icon);

  const title = document.createElement("span");
  title.className = "tweety-summary-title";
  title.textContent = "Notifications";
  summary.appendChild(title);

  return summary;
}

import { markItemAsRead } from "../../fetchers.js";
import { DETAILS_ID } from "../../handleNotifications.js";
import { renderNotifications } from "../../helpers.js";
import {
  getNotifications,
  getUnread,
  setNotifications,
  setUnread,
} from "../../state.js";
import { UserNotificationStatus, UserNotificationType } from "../../types.js";
import { openDetails } from "../details/details.js";
import { hideOverlay, showOverlay } from "../overlay/overlay.js";
import { ToastStatus, addToast } from "../toast/toast.js";

/**
 * Checks if a given date string is a valid date.
 * @param {string} dateString - The date string to be validated.
 * @returns {boolean} - Returns true if the date string is valid, otherwise false.
 */
export function isValidDate(dateString) {
  if (!dateString) return false;
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

/**
 * Returns the icon name based on the notification type.
 * @param {import("../../types.js").UserNotification} notification - The notification object.
 * @returns {string} - The icon name.
 */
export function notificationTypeIcon(notification) {
  switch (notification.type) {
    case UserNotificationType.assignmentCreated:
      return "hrm-icon-assignment";
    case UserNotificationType.assignmentReassigned:
      return "hrm-icon-assignment_return";
    case UserNotificationType.assignmentGraded:
    case UserNotificationType.assignmentSubmitted:
      return "hrm-icon-assignment_turned_in";
    default:
      return "hrm-icon-assignment";
  }
}

/**
 * Create an notification <item/> element to the DOM
 * content: a notification list
 * @param {HTMLElement} container  - will be replaced by the <details/> element
 * @param {import("../../types.js").UserNotification} notification - list of notification to be displayed
 */
export function renderItem(container, notification) {
  const isUnread = notification.status === UserNotificationStatus.UNREAD;

  const item = document.createElement("li");
  item.className = `tweety-item ${isUnread ? "is-unread" : ""}`;
  item.setAttribute("tabindex", "0");

  // Notification type Icon
  const statusCol = document.createElement("div");
  statusCol.className = "tweety-item-status";
  statusCol.setAttribute("role", "status");

  const icon = document.createElement("span");
  icon.className = `hrm-icon hrm-icon-m ${notificationTypeIcon(notification)}`;
  icon.setAttribute("aria-hidden", "true");
  statusCol.appendChild(icon);

  item.appendChild(statusCol);

  const messages = document.createElement("div");
  messages.className = "tweety-item-messages";

  // Notification Title
  const title = document.createElement("div");
  title.className = "tweety-item-title";
  title.innerText = notification.title;

  messages.appendChild(title);

  // Notification Timestamp
  const timestamp = document.createElement("div");
  timestamp.className = "tweety-item-body";
  const hasDate = isValidDate(notification.timestamp);

  if (hasDate) {
    timestamp.setAttribute("role", "date");
    const formattedDate = new Date(
      parseInt(notification.timestamp, 10) * 1000
    ).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    timestamp.innerText = formattedDate;
  }

  messages.appendChild(timestamp);

  // Appends all messages to the item
  item.appendChild(messages);

  // Action
  item.addEventListener("click", async () => {
    if (isUnread) {
      showOverlay();

      try {
        const updatedNotification = await markItemAsRead(notification.id);
        const currentNotifications = getNotifications();
        const currentUnread = getUnread();

        const newNotifications = [
          ...currentNotifications.filter(
            (currentNotification) => currentNotification.id !== notification.id
          ),
          updatedNotification,
        ];

        setNotifications(newNotifications);
        setUnread(currentUnread - 1);
        renderNotifications();
        openDetails(DETAILS_ID);
      } catch (error) {
        addToast(ToastStatus.ERROR, "Error while updating this notification");
        hideOverlay();
        throw new Error(`Failed to mark item as read: ${error}`);
      }

      hideOverlay();
    }
    if (notification.link) {
      window.location.assign(notification.link);
    }
  });

  container.appendChild(item);
}

/** @type {UserNotification[]} */
let notifications = [];

/** @type {number} */
let unread;

/**
 * @function
 * @returns {UserNotification[]} current notifications object
 */
const getNotifications = () => notifications;

/**
 * @function
 * @returns {number} current number of unread notifications
 */
const getUnread = () => unread;

/**
 * Replace the current global notification object with newNotifications
 * @param {UserNotification[]} newNotifications
 */

function setNotifications(newNotifications) {
  notifications = newNotifications;
}

/**
 * Replace the current global number of unread notifications with newUnread
 * @param {number} newUnread
 */

function setUnread(newUnread) {
  unread = newUnread;
}

export { getNotifications, getUnread, setNotifications, setUnread };

/**
 * @readonly
 * @enum {string}
 */
export const UserNotificationStatus = {
  UNREAD: "Unread",
  READ: "Read",
};

/**
 * @readonly
 * @enum {string}
 */
export const UserNotificationType = {
  assignmentCreated: "AssignmentCreated",
  assignmentReassigned: "AssignmentReassigned",
  assignmentGraded: "AssignmentGraded",
  assignmentSubmitted: "AssignmentSubmitted", // Student submitted assignment
};

/**
 * @typedef {object} UserNotification
 * @property {string} id
 * @property {UserNotificationStatus} status
 * @property {string} title              - Main Notification data (book title, marketing)
 * @property {UserNotificationType} type - AssignmentCreated|AssignmentReassigned|Marketing... TBD
 * @property {string} version            - Notification schema version
 * @property {string} timestamp          - Notification creating date
 * @property {string} [body]             - Notification data needed to build the description
 * @property {string} [imageUrl]         - Marketing image, book cover...
 * @property {string} [link]             - Where clicking the notification leads TODO
 */

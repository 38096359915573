define("@makemusicinc/sm-ember-models/utils/group-classroom-by-school", ["exports", "@ember/object", "@ember/array"], function (_exports, _object, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = groupClassroomBySchool;
  function groupClassroomBySchool() {
    let _classrooms = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let exportArchived = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const groupedActiveClassroomsMap = {};
    const groupedClassroomsMap = {};
    const classrooms = (0, _array.A)(_classrooms);
    if (classrooms.length === 1) {
      const isActive = !(0, _object.get)(classrooms, 'firstObject.archived');
      const activeClassrooms = isActive ? classrooms : (0, _array.A)();
      return exportArchived ? [activeClassrooms, classrooms] : activeClassrooms;
    }
    classrooms.sortBy('name').forEach(classroom => {
      const schoolName = (0, _object.get)(classroom, 'school.name') || 'Private classes';
      const isActive = !classroom.archived;
      if (isActive) {
        if (groupedActiveClassroomsMap[schoolName]) {
          groupedActiveClassroomsMap[schoolName].options.push(classroom);
        } else {
          groupedActiveClassroomsMap[schoolName] = {
            groupName: schoolName,
            options: [classroom]
          };
        }
      }
      if (!exportArchived) {
        return;
      }
      if (groupedClassroomsMap[schoolName]) {
        groupedClassroomsMap[schoolName].options.push(classroom);
      } else {
        groupedClassroomsMap[schoolName] = {
          groupName: schoolName,
          options: [classroom]
        };
      }
    });
    return exportArchived ? [Object.values(groupedActiveClassroomsMap), Object.values(groupedClassroomsMap)] : Object.values(groupedActiveClassroomsMap);
  }
});
const TOAST_LIST_ID = "tweety-toast-list";

/**
 * @readonly
 * @enum {string}
 */
export const ToastStatus = {
  ERROR: "error",
  SUCCESS: "success",
};

/**
 *
 * @param {ToastStatus} type
 * @param {string} message
 */
export function createToast(type, message) {
  const toast = document.createElement("div");
  toast.role = "status";
  toast.classList.add("tweety-toast", `tweety-toast-${type}`);

  const messageElement = document.createElement("span");
  messageElement.textContent = message;
  messageElement.classList.add("tweety-toast-message");

  const closeButton = document.createElement("button");
  closeButton.ariaLabel = "Delete this message";
  closeButton.type = "button";
  closeButton.classList.add(
    "notifier-message-close-btn",
    "hrm-icon",
    "hrm-icon-close",
    "hrm-icon-s"
  );

  toast.appendChild(messageElement);
  toast.appendChild(closeButton);

  closeButton.addEventListener("click", () => {
    closeButton.parentElement?.remove();
  });

  // automatically delete the toast after 5 seconds on screen
  setTimeout(() => {
    closeButton.parentElement?.remove();
  }, 5000);

  return toast;
}

export function createToastList() {
  const toastList = document.createElement("div");
  toastList.id = TOAST_LIST_ID;
  toastList.classList.add("tweety-toast-list");
  toastList.setAttribute("data-testid", TOAST_LIST_ID);

  return toastList;
}

/**
 *
 * @param {ToastStatus} type
 * @param {string} message
 */
export function addToast(type, message) {
  const toast = createToast(type, message);
  const toastList = document.getElementById(TOAST_LIST_ID);

  if (!toastList) document.body.appendChild(createToastList());

  document.getElementById(TOAST_LIST_ID)?.appendChild(toast);
}

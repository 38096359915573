/**
 * Notifications Payload
 * @typedef {Object} NotificationPayload
 * @property {import("./types").UserNotification[]} notifications - list of current user notifications
 * @property {number} unread - current number of unread notifications

 */

let currentEnv;
let getAPIEndpoints;
let mockData = {};

/**
 * This function sets the mock data for dev purposes.
 *
 * @param {Object} data - The mock data to be set.
 * @throws {TypeError} If the provided argument is not an object.
 *
 * @returns {Promise<void>}
 */
export function setMockData(data) {
  return new Promise((resolve, reject) => {
    if (typeof data !== "object" || data === null) {
      reject(new TypeError('The "data" argument must be an object.'));
      return;
    }
    mockData = data;
    resolve();
  });
}

/**
 *
 * @param {function} newGetAPIEndpoint
 */
export function setGetAPIEndpoints(newGetAPIEndpoint) {
  getAPIEndpoints = newGetAPIEndpoint;
}

/**
 * @param {string} newEnv
 */
export function setCurrentEnv(newEnv) {
  currentEnv = newEnv;
}
/**
 *
 * @param {string} [url]
 * @param {Object} [options]
 * @returns
 */
async function defaultFetcher(url, options) {
  const defaultOptions = {
    credentials: "include",
    headers: { accept: "application/vnd.api+json" },
    method: "GET",
  };

  if (url) {
    const response = await fetch(url, { ...defaultOptions, ...options });
    const data = await response.json();

    return data;
  }
}

/**
 * return current user notifications
 * @returns {Promise<NotificationPayload>}:
 */
export function fetchNotifications() {
  if (currentEnv === "development") {
    return Promise.resolve({
      notifications: mockData.notifications,
      unread: 3,
    });
  }

  const { getNotifications } = getAPIEndpoints(currentEnv);

  return defaultFetcher(getNotifications);
}

/**
 * @param {string} itemId
 * @returns {Promise<import("./types").UserNotification>}
 */
export function markItemAsRead(itemId) {
  if (currentEnv === "development") {
    return Promise.resolve({
      notifications: mockData.markOneAsRead,
      unread: 2,
    });
  }

  const { markOneAsRead } = getAPIEndpoints(currentEnv, itemId);

  return defaultFetcher(markOneAsRead, {
    method: "PATCH",
  });
}

/**
 * @returns {Promise<NotificationPayload>}
 */
export function markAllAsRead() {
  if (currentEnv === "development") {
    return Promise.resolve({
      notifications: mockData.markAllAsRead,
      unread: 0,
    });
  }

  const { markAllAsRead } = getAPIEndpoints(currentEnv);

  return defaultFetcher(markAllAsRead, {
    method: "PATCH",
  });
}

define("@makemusicinc/sm-ember-models/adapters/recipient", ["exports", "@makemusicinc/sm-ember-models/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class RecipientAdapter
   * @constructor
   * @extends ApplicationAdapter
   */

  class Recipient extends _application.default {
    /**
     * Extends the [Ember Data JSON API Adapter](https://api.emberjs.com/ember-data/3.15/classes/JSONAPIAdapter/methods/query?anchor=query)
     *  query method with a `findOrCreate` adapter option:
     *  - the idea is to mimic a BATCH create while letting the Backend able
     *    to return existing records matching a given email address (find - or - create)
     *  - it will generate an array of JSON-API create payloads from an email address array
     *  - it will POST (instead of GET)
     *
     * @method query
     * @public
     * @param {Object} store
     * @param {Object} type
     * @param {Object} query
     */

    query(store, type, query) {
      const {
        adapterOptions = {}
      } = query;
      if (adapterOptions.findOrCreate) {
        const {
          emails = []
        } = query;
        const payload = this.generateFindOrCreatePayload(emails);
        return this.findOrCreate(query, payload);
      }
      return super.query(store, type, query);
    }

    /**
     * Generates an array of JSON-API create payloads from an email address array
     * @method generateFindOrCreatePayload
     * @private
     * @param {Array} emailAddresses
     */

    generateFindOrCreatePayload() {
      let emailAddresses = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      const payload = {
        data: []
      };
      emailAddresses.forEach(email => {
        payload.data.push({
          attributes: {
            email
          },
          type: 'recipients'
        });
      });
      return payload;
    }

    /**
     * Request handler
     * * use regular endpoint URL
     * * use POST method instead of GET
     * @method findOrCreate
     * @private
     * @param {Object} type
     * @param {Object} query
     * @param {Object} payload
     */

    findOrCreate(query, payload) {
      const url = this.urlForQuery(query, 'recipient');
      return this.ajax(url, 'POST', {
        data: payload
      });
    }
  }
  _exports.default = Recipient;
});
import { endpointsConfig } from "@makemusicinc/mmc-js-common";
import { setGetAPIEndpoints } from "../fetchers";
import { tweety } from "../handleNotifications";

setGetAPIEndpoints((env, itemId) => {
  const API = endpointsConfig(env).notificationEndpoint;

  return {
    getNotifications: API,
    markOneAsRead: `${API}/${itemId}`,
    markAllAsRead: API,
  };
});

tweety();

define("@makemusicinc/sm-ember-models/models/grade", ["exports", "@ember-data/model", "@ember/object"], function (_exports, _model, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * An object bearing the grade for one assignment, for one given RubricsTask
   *
   * @class Grade
   * @constructor
   */
  let Grade = _exports.default = (_dec = (0, _model.attr)('number', {
    defaultValue: 0
  }), _dec2 = (0, _model.belongsTo)('level'), _dec3 = (0, _model.belongsTo)('rubrics-task'), _dec4 = (0, _model.belongsTo)('submission'), _class = class Grade extends _model.default {
    constructor() {
      super(...arguments);
      /**
       * The grade given to a submission for a specific RubricsTask (0 - 100)
       *
       * @property score
       * @category Attribute
       * @type {Number}
       * @default 0
       */
      _initializerDefineProperty(this, "score", _descriptor, this);
      /**
       * The level that was chosen for this grade
       *
       * @property level
       * @category Relationship
       * @type {Level}
       */
      _initializerDefineProperty(this, "level", _descriptor2, this);
      /**
       * The rubricsTask this grade relates to
       *
       * @property rubricsTask
       * @category Relationship
       * @type {RubricsTask}
       */
      _initializerDefineProperty(this, "rubricsTask", _descriptor3, this);
      /**
       * The submission this grade applies to
       *
       * @property submission
       * @category Relationship
       * @type {Submission}
       */
      _initializerDefineProperty(this, "submission", _descriptor4, this);
    }
    /**
     * The displayed grade, that depends on the RubricsTask's weight
     *
     * @property weightedScore
     * @category Property
     * @type {Number}
     */

    get weightedScore() {
      const rubricsTask = (0, _object.get)(this, 'rubricsTask');
      const weight = (0, _object.get)(rubricsTask, 'weight');
      const weightedScore = this.score * weight / 100;
      return Math.round(weightedScore * 100) / 100; // round to 2 decimals
    }

    /**
     * Update score from a weighted-score
     * @method setScoreFromWeightedScore
     * @category Method
     * @param {Number} value
     */

    setScoreFromWeightedScore() {
      let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      const rubricsTask = (0, _object.get)(this, 'rubricsTask');
      const weight = (0, _object.get)(rubricsTask, 'weight');
      const weightedScore = Math.round(value * 100) / 100; // round to 2 decimals
      const score = weightedScore * 100 / weight;
      (0, _object.set)(this, 'score', score);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "score", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "level", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rubricsTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "submission", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});
define("@makemusicinc/sm-ember-models/models/assignment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Represents the unit of homework one student must do, and that the teacher grades
   * It is a TaskPart assigned to a Student
   *
   * @class Assignment
   * @constructor
   */
  let Assignment = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('number'), _dec3 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec4 = (0, _model.belongsTo)('user'), _dec5 = (0, _model.belongsTo)('assigned-task'), _dec6 = (0, _model.belongsTo)('task-part'), _dec7 = (0, _model.hasMany)('submission'), _class = class Assignment extends _model.default {
    constructor() {
      super(...arguments);
      /**
       * Current Assignment Status
       * Possible values: accepted, submitted, late, rejected, pending, unsubmittable
       *
       * @property status
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "status", _descriptor, this);
      /**
       * Score of the assignment
       *
       * @property score
       * @category Attribute
       * @type {Number}
       */
      _initializerDefineProperty(this, "score", _descriptor2, this);
      /**
       * Is this assignment archived
       *
       * @property archived
       * @category Attribute
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "archived", _descriptor3, this);
      /**
       * The student that received this assignment
       *
       * @property user
       * @category Relationship
       * @type {User}
       */
      _initializerDefineProperty(this, "student", _descriptor4, this);
      /**
       * The assignedTask that received this assignment
       *
       * @property assignedTask
       * @category Relationship
       * @type {AssignedTask}
       */
      _initializerDefineProperty(this, "assignedTask", _descriptor5, this);
      /**
       * The taskPart on which this assignment is defined
       *
       * @property taskPart
       * @category Relationship
       * @type {TaskPart}
       */
      _initializerDefineProperty(this, "taskPart", _descriptor6, this);
      /**
       * The submissions to this assignment
       *
       * @property submissions
       * @category Relationship
       * @type {Submission}
       */
      _initializerDefineProperty(this, "submissions", _descriptor7, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "status", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "score", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "archived", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "student", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "assignedTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "taskPart", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "submissions", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});
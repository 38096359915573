define("@makemusicinc/sm-ember-models/models/rubric", ["exports", "@ember-data/model", "@ember/object", "@ember/utils"], function (_exports, _model, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Represents one criterion for grading assignments
   * Created by a teacher or pre-defined
   *
   * @class Rubric
   * @constructor
   */
  let Rubric = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string', {
    defaultValue: '#3B3B3B'
  }), _dec5 = (0, _model.hasMany)('rubrics-task'), _dec6 = (0, _model.hasMany)('level'), _dec7 = (0, _model.belongsTo)('rubric', {
    inverse: 'child'
  }), _dec8 = (0, _model.belongsTo)('rubric', {
    inverse: 'parent'
  }), _class = class Rubric extends _model.default {
    constructor() {
      super(...arguments);
      /**
       * The type of the rubric
       *
       * @property rubricType
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "rubricType", _descriptor, this);
      /**
       * The name of the rubric
       *
       * @property name
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "name", _descriptor2, this);
      /**
       * The displayed description of this rubric
       *
       * @property description
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "description", _descriptor3, this);
      /**
       * The displayed color of this rubric
       *
       * @property color
       * @category Attribute
       * @type {String}
       * @default "#3B3B3B"
       */
      _initializerDefineProperty(this, "color", _descriptor4, this);
      /**
       * The rubricsTasks defined with this rubric
       *
       * @property rubricsTasks
       * @category Relationship
       * @type {RubricsTask}
       */
      _initializerDefineProperty(this, "rubricsTasks", _descriptor5, this);
      /**
       * The levels this rubric contains
       *
       * @property levels
       * @category Relationship
       * @type {Level}
       */
      _initializerDefineProperty(this, "levels", _descriptor6, this);
      /**
       * The rubric it was created from (can be null)
       *
       * @property parent
       * @category Relationship
       * @type {Rubric}
       */
      _initializerDefineProperty(this, "parent", _descriptor7, this);
      /**
       * The rubric that was created from it (can be null)
       *
       * @property child
       * @category Relationship
       * @type {Rubric}
       */
      _initializerDefineProperty(this, "child", _descriptor8, this);
    }
    /**
     * @property hasAChild
     * @category Property
     * @type {Boolean}
     */

    get hasAChild() {
      const child = (0, _object.get)(this, 'child');
      return child && (0, _utils.isPresent)(child.content);
    }

    /**
     * @property isAssessmentRubric
     * @category Property
     * @type {Boolean}
     */

    get isAssessmentRubric() {
      return this.rubricType === 'assessment';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "rubricType", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "color", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rubricsTasks", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "levels", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "parent", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "child", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});
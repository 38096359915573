define("@makemusicinc/sm-ember-models/models/playlist", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PLAYLIST_LIMIT = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * @class Playlist
   * @constructor
   */

  const PLAYLIST_LIMIT = _exports.PLAYLIST_LIMIT = 50;
  let Playlist = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('date'), _dec3 = (0, _model.attr)('date'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.hasMany)('items-playlist'), _class = class Playlist extends _model.default {
    constructor() {
      super(...arguments);
      /**
       * The name of the playlist
       *
       * @property name
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "name", _descriptor, this);
      /**
       * The creation date of the playlist
       *
       * @property createdAt
       * @category Attribute
       * @type {Date}
       */
      _initializerDefineProperty(this, "createdAt", _descriptor2, this);
      /**
       * The last update date of the playlist
       *
       * @property updatedAt
       * @category Attribute
       * @type {Date}
       */
      _initializerDefineProperty(this, "updatedAt", _descriptor3, this);
      /**
       * The description of the playlist
       *
       * @property description
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "description", _descriptor4, this);
      /**
       * The display name of the author of the playlist
       *
       * @property authorName
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "authorName", _descriptor5, this);
      /**
       * URL for the cover image
       *
       * @property coverUrl
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "coverUrl", _descriptor6, this);
      /**
       * UID for the cover image
       *
       * @property coverUid
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "coverUid", _descriptor7, this);
      /**
       * Indicates if the playlist has been shared publicly
       *
       * @property public
       * @category Attribute
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "public", _descriptor8, this);
      /**
       * The slug identifier generated for publicly shared playlist
       * Used to generate the related Search URL to open the shared playlist
       *
       * @property publicSlug
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "publicSlug", _descriptor9, this);
      /**
       * The User UID of the playlist owner
       *
       * @property userUid
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "userUid", _descriptor10, this);
      /**
       * The polymorphic item attached in the playlist
       * could either be a Digital Sheet Music or a Movement
       *
       * @property itemsPlaylists
       * @category Relationship
       * @type {Array<ItemsPlaylist>}
       */
      _initializerDefineProperty(this, "itemsPlaylists", _descriptor11, this);
    }
    /**
     * Maximum slots available in a playlist
     *
     * @property itemsPlaylistLimit
     * @category Property
     * @type {Number}
     */

    get itemsPlaylistLimit() {
      return PLAYLIST_LIMIT;
    }

    /**
     * Total of slots used in the playlist
     *
     * @property itemsPlaylistLimit
     * @category Property
     * @type {Number}
     */

    get itemsPlaylistsCount() {
      // Workaround for Ember-data types expected another type than string
      const keyForItemsPlaylists = 'itemsPlaylists';
      return this.hasMany(keyForItemsPlaylists).ids().length || 0;
    }

    /**
     * Maximum slots available in the playlist
     *
     * @property slotsLeft
     * @category Property
     * @type {Number}
     */

    get slotsLeft() {
      return this.itemsPlaylistLimit - this.itemsPlaylistsCount;
    }

    /**
     * Indicates if user can no longer
     * add content in the playlist
     *
     * @property isFull
     * @category Property
     * @type {Boolean}
     */

    get isFull() {
      return this.slotsLeft === 0;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "authorName", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "coverUrl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "coverUid", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "public", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "publicSlug", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "userUid", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "itemsPlaylists", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});
define("@makemusicinc/sm-ember-models/serializers/sharing", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * @class SharingSerializer
   * @constructor
   * @extends JSONAPISerializer
   */

  class Sharing extends _jsonApi.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "attrs", {
        viewCount: {
          serialize: false
        },
        playCount: {
          serialize: false
        },
        downloadCount: {
          serialize: false
        }
      });
      /**
       * Tells if a payload is a RecipientSharing batch create payload.
       *
       * @method isRecipientsSharingsBatchUpdatePayload
       * @param {Object} payload
       * @private
       */
      _defineProperty(this, "isRecipientsSharingsBatchUpdatePayload", payload => Array.isArray(payload.data) && payload.data.length > 0 && payload.data[0].type === 'recipients-sharings');
      /**
       * @method extractResourceLinkage
       * @param {Object} ResourcePayload
       * @param {string} ResourcePayload.id
       * @param {string} ResourcePayload.type
       * @private
       */
      _defineProperty(this, "extractResourceLinkage", _ref => {
        let {
          id,
          type
        } = _ref;
        return {
          id,
          type
        };
      });
      /**
       * Creates an API Sharing update payload from a RecipientSharing batch create payload.
       *
       * @method buildSharingPayloadFromRecipientsSharingBatchCreatePayload
       * @param {Object} realPayload
       * @param {Object} sharing
       * @private
       */
      _defineProperty(this, "buildSharingPayloadFromRecipientsSharingBatchCreatePayload", (realPayload, sharing) => {
        const fakeSharingPayload = sharing.serialize();
        const sortedRealPayloadData = realPayload.data.sort((a, b) => parseInt(a.id) - parseInt(b.id));
        const newRecipientsSharingsResourceLinkage = sortedRealPayloadData.map(this.extractResourceLinkage);
        fakeSharingPayload.data.id = sharing.id;
        if (fakeSharingPayload.data.relationships) {
          fakeSharingPayload.data.relationships['recipients-sharings'] = [...newRecipientsSharingsResourceLinkage, ...(fakeSharingPayload.data.relationships['recipients-sharings'] || [])];
        } else {
          fakeSharingPayload.data.relationships = {
            'recipients-sharings': newRecipientsSharingsResourceLinkage
          };
        }
        fakeSharingPayload.included = sortedRealPayloadData;
        return fakeSharingPayload;
      });
    }
    /**
     * Extends the [Ember Data JSON API Serializer](https://api.emberjs.com/ember-data/3.15/classes/JSONAPISerializer/methods/query?anchor=serialize)
     * serialize method with `addRecipientsSharings` adapter option.
     *  - it would reduce dramatically the amount of needed requests and only ids are necessary
     *  - in order to add a set of recipients sharings: `POST /sharings/:id/recipients_sharings`
     *  - the serialized will take those created recipients sharings and create a sharing update payload from it.
     *
     * @method serialize
     * @public
     * @param {Object} snapshot
     */

    serialize(snapshot, options) {
      const {
        adapterOptions = {}
      } = snapshot;
      if (adapterOptions.addRecipientsSharings) {
        return this.serializeUpdateRecipientSharings(snapshot.id, adapterOptions.addRecipientsSharings);
      }
      if (adapterOptions.removeAllRecipientsSharings) {
        return this.serializeRemoveAllRecipientsSharings();
      }
      return super.serialize(snapshot, options);
    }
    /**
     * Normalize record with an update response
     * When the payload is identified as a recipient-sharings batch update payload, it will create a fake payload as if the sharing was returned by the API
     * @method normalizeUpdateRecordResponse
     * @private
     * @param {Object} store
     * @param {Object} model
     * @param {Object} payload
     * @param {string} id
     * @param {string} requestType
     */
    normalizeUpdateRecordResponse(store, model, payload, id, requestType) {
      let payloadToProcess = payload;

      // case were we create some recipients-sharings through adapter option "addRecipientsSharings"
      if (this.isRecipientsSharingsBatchUpdatePayload(payload)) {
        const sharing = store.peekRecord('sharing', id);
        payloadToProcess = this.buildSharingPayloadFromRecipientsSharingBatchCreatePayload(payload, sharing);
      }
      return super.normalizeUpdateRecordResponse(store, model, payloadToProcess, id, requestType);
    }

    /**
     * Generates a payload with an empty array to clear all relationships
     * @method serializeRemoveAllRecipientsSharings
     * @private
     */
    serializeRemoveAllRecipientsSharings() {
      return {
        data: []
      };
    }

    /**
     * Generates a payload from a recipient ids array
     * @method serializeUpdateRecipientSharings
     * @private
     * @param {Array} ids
     */
    serializeUpdateRecipientSharings(sharingId) {
      let recipientsSharings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      const payload = {
        data: []
      };
      recipientsSharings.forEach(_ref2 => {
        let {
          interactivePrint,
          recipientId
        } = _ref2;
        payload.data.push({
          type: 'recipients-sharings',
          attributes: {
            'interactive-print': interactivePrint
          },
          relationships: {
            recipient: {
              data: {
                id: recipientId,
                type: 'recipients'
              }
            },
            sharing: {
              data: {
                id: sharingId,
                type: 'sharings'
              }
            }
          }
        });
      });
      return payload;
    }
  }
  _exports.default = Sharing;
});
define("@makemusicinc/sm-ember-models/models/movement", ["exports", "@ember-data/model", "@makemusicinc/sm-ember-models/models/item", "@makemusicinc/sm-ember-models/models/bundle"], function (_exports, _model, _item, _bundle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * @class Movement
   * @constructor
   */
  let Movement = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec4 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec5 = (0, _model.attr)('number'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('boolean', {
    defaultValue: false
  }), _dec8 = (0, _model.attr)('boolean'), _dec9 = (0, _model.belongsTo)('book', {
    inverse: null
  }), _dec10 = (0, _model.hasMany)('task-template'), _dec11 = (0, _model.hasMany)('bundle'), _dec12 = (0, _model.hasMany)('assigned-task'), _class = class Movement extends _item.default {
    constructor() {
      super(...arguments);
      /**
       * The title of the movement
       *
       * @property title
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "title", _descriptor, this);
      /**
       * Composer of the movement (computed by Rails)
       *
       * @property composer
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "composer", _descriptor2, this);
      /**
       * Is this movement for premium users only?
       *
       * @property premium
       * @category Attribute
       * @type {Boolean}
       * @default true
       */
      _initializerDefineProperty(this, "premium", _descriptor3, this);
      /**
       * Indicates if this movement can be previewed
       *
       * @property inBookPreview
       * @category Attribute
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "inBookPreview", _descriptor4, this);
      /**
       * The ordering number of the movement
       * Used when sorted with the other movements of its parent title
       *
       * @property ordering
       * @category Attribute
       * @type {Number}
       */
      _initializerDefineProperty(this, "ordering", _descriptor5, this);
      /**
       * The score-type of the movement
       *
       * @property scoreType
       * @type {String}
       */
      _initializerDefineProperty(this, "scoreType", _descriptor6, this);
      /**
       * Indicates if the movement
       * can be assigned by the current user
       *
       * @property assignable
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "assignable", _descriptor7, this);
      /**
       * Indicates if the current movement
       * is accessible publicly
       * (ie. has a related public sharing)
       *
       * @property public
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "public", _descriptor8, this);
      /**
       * The book of the movement (through title)
       *
       * @property book
       * @type {Book}
       */
      _initializerDefineProperty(this, "book", _descriptor9, this);
      /**
       * TaskTemplates defined with this movement
       *
       * @property taskTemplates
       * @category Relationship
       * @type {Array<TaskTemplate>}
       */
      _initializerDefineProperty(this, "taskTemplates", _descriptor10, this);
      /**
       * The bundles of the movement
       *
       * @property bundle
       * @type {Bundle}
       */
      _initializerDefineProperty(this, "bundles", _descriptor11, this);
      /**
       * AssignedTasks defined with this movement
       *
       * @property assignedTasks
       * @category Relationship
       * @type {Array<AssignedTask>}
       */
      _initializerDefineProperty(this, "assignedTasks", _descriptor12, this);
    }
    /**
     * Checks if the movement is related to published content
     *
     * @property isPublisherScore
     * @type {Boolean}
     */

    get isPublisherScore() {
      return this.scoreType === _bundle.ScoreTypes.publisherScore;
    }

    /**
     * Checks if the movement is a composition
     *
     * @property isComposition
     * @type {Boolean}
     */

    get isComposition() {
      return this.scoreType === _bundle.ScoreTypes.composition;
    }

    /**
     * Checks if the movement is a Sight-Reading Outline
     *
     * @property isSightReadingOutline
     * @type {Boolean}
     */

    get isSightReadingOutline() {
      return this.scoreType === _bundle.ScoreTypes.sightReadingOutline;
    }

    /**
     * Checks if the movement can be added into a playlist
     *
     * @property canBeAddedIntoPlaylists
     * @type {Boolean}
     */

    get canBeAddedIntoPlaylists() {
      switch (this.scoreType) {
        case _bundle.ScoreTypes.composition:
          // Workaround to determine if the current user is the owner:
          // Only the composition owner is allowed to add it into a playlist
          return this.assignable;
        case _bundle.ScoreTypes.publisherScore:
        case _bundle.ScoreTypes.sightReadingOutline:
          return true;
        default:
          return false;
      }
    }

    /**
     * Checks if the movement can be favorited
     *
     * @property canBeFavorited
     * @type {Boolean}
     */

    get canBeFavorited() {
      switch (this.scoreType) {
        case _bundle.ScoreTypes.composition:
        case _bundle.ScoreTypes.sightReadingOutline:
          // Workaround to determine if the current user is the owner:
          // Only the content owner can favorite it
          return this.assignable;
        case _bundle.ScoreTypes.publisherScore:
          return true;
        default:
          return false;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "composer", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "premium", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "inBookPreview", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "ordering", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "scoreType", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "assignable", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "public", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "book", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "taskTemplates", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "bundles", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "assignedTasks", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});
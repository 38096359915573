define("@makemusicinc/sm-ember-models/models/carousel-selection", ["exports", "@ember-data/model", "@ember/object", "@ember/application"], function (_exports, _model, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * A Selection of books exposed in a carousel
   *
   * @class CarouselSelection
   * @constructor
   */
  let CarouselSelection = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('number'), _class = class CarouselSelection extends _model.default {
    constructor() {
      super(...arguments);
      /**
       * The title of the selection
       *
       * @property title
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "title", _descriptor, this);
      /**
       * The reference of the image displayed in the carousel
       *
       * @property image
       * @category Attribute
       * @type {String}
       */
      _initializerDefineProperty(this, "image", _descriptor2, this);
      /**
       * The ordering number of the selection
       *
       * @property ordering
       * @category Attribute
       * @type {Number}
       */
      _initializerDefineProperty(this, "ordering", _descriptor3, this);
    }
    /**
     * The query to get the books of the selection in the search app
     *
     * @property searchUrl
     * @category Property
     * @type {String}
     */

    get searchUrl() {
      // @ts-ignore https://github.com/emberjs/ember.js/issues/19916
      const ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      const url = new URL(`${ENV.contentSearchUrl}/search`);
      url.searchParams.set('carouselSelection', (0, _object.get)(this, 'id'));
      return url.href;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "image", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ordering", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});